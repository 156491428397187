<template>
    <div class="type-20-marketingSlide d-flex flex-column"
    :style="{ 'background-image': 'url(' + innerImage + ')' }"
    @click.self="triggerChange">
        <!-- {{ baseUrl + item.img }} -->
        <div v-if="item.title"
        @click="triggerChange"
        class="type-20-marketingSlide-title"
        v-html="item.title"></div>
        <div v-if="item.subtitle_first || item.subtitle_second"
        @click="triggerChange"
        class="type-20-marketingSlide-subtitle d-flex flex-column align-items-start">
            <span class="subs" v-if="item.subtitle_first" v-text="item.subtitle_first"></span>
            <span class="subs" v-if="item.subtitle_second" v-text="item.subtitle_second"></span>
        </div>
        <div v-if="item.innerImage"
        @click="triggerChange"
        class="type-20-marketingSlide-innerImage">
            <div class="blackSticker">
                <span class="blackSticker-text">Aktion:</span>
                <span class="blackSticker-content" v-text="item.sticker_content"></span>
            </div>
            <img :src="baseUrl + item.innerImage" alt="">
        </div>
        <div v-if="item.inner_content"
        @click="triggerChange"
        class="type-20-marketingSlide-content" v-html="item.inner_content"></div>
        <div v-if="item.slide_links.length" class="type-20-marketingSlide-actionButtons d-flex justify-content-between">
          <a v-for="(link, j) in item.slide_links"
          :key="'sl_lnk-' + j"
          class="btn btn-primary cursor-pointer"
          @click.stop.prevent="triggerLink(link)"
          v-text="link.title"></a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'marketingSlide',
  props: {
    item: {
      type: Object
    },
    baseUrl: {
      type: String
    }
  },
  computed: {
    innerImage () {
      return this.baseUrl + this.item.img
    }
  },
  methods: {
    triggerChange (e) {
      this.$emit('changeSlides')
    },
    triggerLink (item) {
      window.open((item.file ? (this.baseUrl + item.file) : item.link), item.external === '1' ? '_blank' : '_self')
    }
  }
}
</script>
